// externals
import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// libraries
import { BootstrapLoadingState } from '@makemydeal/dr-shared-types';
import { sharedBootstrapSelectors } from '@makemydeal/dr-shared-store';
import { loadingIndicatorUtils, OPEN_DEAL_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

import { initialLoad, fetchBootstrapData } from '../../actions/bootstrapAppActionCreators';

// components
import { LoadingPage } from '../loadingPage/LoadingPage';
import { BootstrapError } from '../bootstrapError/BootstrapError';

export const Bootstrapper: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const loadingState = useSelector(sharedBootstrapSelectors.getBootstrapLoadingState);
    const loadingErrorMessage = useSelector(sharedBootstrapSelectors.getBootstrapErrorMessage);

    useEffect(() => {
        // load the deal props
        dispatch(initialLoad());
        // fetch the deal
        dispatch(fetchBootstrapData(OPEN_DEAL_APP_PREFIX));
    }, [dispatch]); // even though dispatch is a stable reference, it is added to keep the linter happy

    useEffect(() => {
        if (loadingState !== BootstrapLoadingState.LoadedSuccessfully && loadingState !== BootstrapLoadingState.LoadFailed) return;

        loadingIndicatorUtils.removeAllLoadingIndicatorPanels();
    }, [loadingState]);

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0
        });
    }, [location]);

    if (loadingErrorMessage) return <BootstrapError loadingError={loadingErrorMessage} />;

    return (
        <>
            {loadingState === BootstrapLoadingState.Loading && <LoadingPage />}
            {loadingState === BootstrapLoadingState.LoadedSuccessfully && children}
            {loadingState === BootstrapLoadingState.LoadFailed && <BootstrapError />}
            {loadingState.toString() === '' && 'Error occurred bootstrapping the app - unsupport state.'}
        </>
    );
};
