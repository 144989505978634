import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { PropsRoute } from '@makemydeal/dr-activities-common';

import { IRootProps } from '../types';
import { getAllRoutes } from '../utils/routes';

import Activity from './Activity';
import { StyledOpenDealRoot } from './Activity.style';

const Root: React.FC<IRootProps> = (props: IRootProps) => {
    const path = props.baseRoute;
    const routes = getAllRoutes(path);

    const getStartingComponent = () => {
        // TODO: Implement code to make decision on the route if needed so the base route can go somewhere
        return routes.DASHBOARD;
    };

    return (
        <StyledOpenDealRoot className="dr-activities-open-deal" data-testid="dr-activities-open-deal-container">
            <Route path={routes.ROOT} render={() => <Redirect to={getStartingComponent()} />} />
            <PropsRoute {...props} path={routes.DASHBOARD} component={Activity} />
        </StyledOpenDealRoot>
    );
};

export default Root;
