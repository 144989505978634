// istanbul ignore file

import styled from 'styled-components';

export const BannerWrapper = styled.div`
    width: auto;
`;

export const StyledBodyContainer = styled.div`
    background-color: ${({ theme }) => theme.tokens.BaseColorWhite};
    padding-top: 5rem;
    padding-bottom: 6rem;
    min-height: 100vh;
`;
