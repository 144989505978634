// libraries
import { Typography } from '@interstate/components/Typography';
import { Action } from '@interstate/components/Action';
import { Alert } from '@interstate/components/Alert';
import { Grid } from '@interstate/components/Grid';

// constants
import { DEALER_SUPPROT_REQUEST_FORM } from '../../../constants';

export const OpenDealDialogErrorContent = ({ targetApp }: { targetApp: string }) => {
    const openSupportTicketUrl = () => {
        window.open(DEALER_SUPPROT_REQUEST_FORM);
    };

    return (
        <>
            <Alert type="error" sx={{ marginBottom: '24px' }} data-testid="open-deal-error-alert">
                <Typography variant="h6">Failed to open {targetApp}</Typography>
                <Typography variant="body-sm">Details: The deal cannot be updated.</Typography>
            </Alert>

            <Grid sx={{ lineHeight: '18px' }}>
                <Typography variant="strong-md">Please contact support:</Typography>
                <Typography variant="body-md">1-888-895-2994 or create a</Typography>
                <Action onClick={openSupportTicketUrl} data-testid="support-ticket">
                    <Typography variant="anchor-inline-md" color="sem.color.surface.button.primary.rest">
                        support ticket
                    </Typography>
                </Action>
            </Grid>
        </>
    );
};
