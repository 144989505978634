import { MouseEventHandler } from 'react';

import { CheckCircleIcon } from '@interstate/components/Icons/CheckCircleIcon';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { ExclamationCircleIcon } from '@interstate/components/Icons';
import { Action } from '@interstate/components/Action';

import { StyledActionContent, StyledCardHeader, StyledCardTitle } from './OpenDealCardHeader.style';

export type CardHeaderProps = {
    title: string;
    showIcon?: boolean;
    isComplete?: boolean;
    action?: string;
    onActionClick?: MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLSpanElement>;
};

export const OpenDealCardHeader = ({ title, showIcon, isComplete, action, onActionClick }: CardHeaderProps) => {
    const theme = useInterstateTheme();

    const competedIcon = <CheckCircleIcon data-testid="card-check-icon" color={theme.tokens.BaseColorGreen500} />;
    const errorIcon = <ExclamationCircleIcon data-testid="card-error-icon" color={theme.tokens.BaseColorRed500} />;

    const icon = isComplete ? competedIcon : errorIcon;

    return (
        <StyledCardHeader className="card-header" data-testid="card-header">
            <StyledCardTitle data-testid="card-title">
                {showIcon && icon}
                {title}
            </StyledCardTitle>
            {action && (
                <Action data-testid="card-action" onClick={onActionClick}>
                    {<StyledActionContent>{action}</StyledActionContent>}
                </Action>
            )}
        </StyledCardHeader>
    );
};
