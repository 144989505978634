import { Store } from 'redux';

import * as changeVehicleActionCreators from '@makemydeal/dr-activities-change-vehicle';
import { BaseMiddleware } from '@makemydeal/dr-activities-common';

import { IConfigOpenDeal, IHostActions } from '../types';

import * as openDealActionCreators from './actionCreators';
import { Actions } from './actions';
import { Selectors } from './selectors';

export class MiddlewareManager extends BaseMiddleware {
    constructor(
        public selectors: Selectors,
        public hostActions: IHostActions,
        public config: IConfigOpenDeal,
        public baseRoute?: string,
        public analyticsAction?: (store: any, key: string, desc: string) => void
    ) {
        super(selectors, analyticsAction);
    }

    protected middlewareFunction = (store: Store<any>, _next: any, action: any): void => {
        switch (action.type) {
            case Actions.SWITCH_VEHICLE: {
                store.dispatch(openDealActionCreators.toggleChangeVehicleEditMode());
                store.dispatch(changeVehicleActionCreators.vehicleChangeSuccess());
                break;
            }
            case Actions.TOGGLE_CHANGE_VEHICLE_EDIT_MODE: {
                store.dispatch(changeVehicleActionCreators.vehicleChangeCancel());
                break;
            }
            case Actions.OPEN_DEAL: {
                store.dispatch(openDealActionCreators.saveDeal(this.selectors));
                break;
            }
            case Actions.SAVE_DEAL_SUCCESS: {
                store.dispatch(openDealActionCreators.openDealInTargetApp(action.payload));
                break;
            }
            case Actions.OPEN_DEAL_IN_TARGET_APP: {
                window.location.href = action.payload.link;
                break;
            }
            default: {
                break;
            }
        }
    };
}
