// externals
import { FC, useRef } from 'react';

// libraries
import { CoxBridgeBar, RenderWhenOktaEnabled } from '@makemydeal/dr-shared-components';

// styles
import { BridgeBarContainer, HeaderContainer, HeaderRoot, TopBarContainer } from './Header.style';

export type HeaderProps = {
    appPrefix: string;
};

const Header: FC<HeaderProps> = (props) => {
    const refRoot = useRef<HTMLDivElement | null>(null);

    return (
        <HeaderRoot data-testid="header-root" ref={refRoot}>
            <HeaderContainer>
                <TopBarContainer>
                    <BridgeBarContainer>
                        <RenderWhenOktaEnabled>
                            <CoxBridgeBar appPrefix={props.appPrefix} />
                        </RenderWhenOktaEnabled>
                    </BridgeBarContainer>
                </TopBarContainer>
            </HeaderContainer>
        </HeaderRoot>
    );
};

export default Header;
