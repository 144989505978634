// externals
import {
    buildUserEventV3,
    EventMapV3,
    buildCDLEventMiddlewareV3,
    buildReadyEventV3,
    BaseDataLayerV3
} from '@makemydeal/dr-google-analytics-utils';

// libraries
// consts/enums
import { deskingActionTypes } from '../actionTypes';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

export const eventMap: EventMapV3 = {
    CDLV3_DATA_READY: (action: any, currentState: any) => {
        const eventData = {
            // add more data here if avaiable in later stages and needed for initial CDL3 setup (part of 49 fields)
        };
        return buildReadyEventV3(eventData as BaseDataLayerV3);
    },
    [deskingActionTypes.CLICKED_COPY_DEAL_URL_CLICKED]: buildUserEventV3({
        action: 'Click',
        element: 'Get Deal Link - Copy Button',
        location: ['Deal Dashboard', 'Current Working Offer', 'Modal'],
        intention: 'Copy Link To Deal'
    }),
    [deskingActionTypes.CLICKED_PUSH_TO_DMS_CONFIRM]: buildUserEventV3({
        action: 'Click',
        element: 'Push to DMS - Confirm Button',
        location: ['Deal Dashboard', 'Deal Progress', 'Modal'],
        intention: 'Navigate to DMS'
    }),
    [deskingActionTypes.CLICKED_BEGIN_CHECKOUT]: buildUserEventV3({
        action: 'Click',
        element: 'Start Checkout - Button',
        location: ['Deal Dashboard', 'Deal Progress'],
        intention: 'Get Start Checkout Link'
    }),
    [deskingActionTypes.SEND_TO_SALES_VIEW_CONFIRM]: buildUserEventV3({
        action: 'Click',
        element: 'Send To Sales View - Confirm Button',
        location: ['Deal Dashboard', 'Current Working Offer', 'Modal'],
        intention: 'Send Offer To Sales View'
    }),
    [deskingActionTypes.CLICKED_SEND_TO_CUSTOMER]: buildUserEventV3({
        action: 'Click',
        element: 'Send To Customer - Button',
        location: ['Deal Dashboard', 'Current Working Offer'],
        intention: 'Send Offer To Customer'
    }),
    [deskingActionTypes.CLICKED_CONTINUE_IN_DESKING_CONFIRM]: buildUserEventV3({
        action: 'Click',
        element: 'Continue In Desking - Confirm Button',
        location: ['Deal Dashboard', 'Current Working Offer', 'Modal'],
        intention: 'Navigate To Desking'
    })
};

export const cdlV3AnalyticsEventMiddleware = buildCDLEventMiddlewareV3({
    eventMap,
    enabled: true,
    middlewareEnabledSelector: featureToggleSelectors.isCDL3EnabledInMV
});
