/**
 * PURPOSE: Provide reducers that are not required for the initial bootstrap of the application.  For the bootstrap related reducers
 *   take a look at `initialReducers.ts`.
 */

// libraries
import { InitContext } from '@makemydeal/dr-shared-store';
import { openDealConfiguration } from '../activities/open-deal-dashboard';
import { changeVehicleConfiguration } from '../activities/change-vehicle';

// reducers
export type RemainingReducers = any;

/**
 * Retrieve reducers that aren't needed for the initial bootstrap of the application.  These reducers can be conditionally included
 * based on feature toggles etc.
 * @param context object that can provide additional context such as feature toggles
 * @returns object containing reducers
 */
export const getRemainingReducers = (context: InitContext): RemainingReducers => {
    const result: RemainingReducers = {
        openDeal: openDealConfiguration.reducer,
        changeVehicle: changeVehicleConfiguration.reducer
    };

    return result;
};
