import styled from 'styled-components';

export const LoadingIndicatorWrapper = styled.div.attrs((props: any) => ({
    ['data-testid' as any]: props['data-testid']
}))`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    min-height: 36px;

    & #interstitial-overlay-root {
        background-color: transparent;
        min-width: 32px;
        min-height: 36px;
    }

    & #interstitial-content-root {
        min-width: 32px;
        box-shadow: none;
    }
`;
