/* istanbul ignore file */
import styled from 'styled-components';

export const SelectInputContainer = styled.div<{ hasValue: boolean }>`
    margin: 16px 0;

    .dealership-select-input-root > label {
        display: none;
    }

    #select-input-options-dealership-select-input {
        color: ${({ theme, hasValue }) => {
            const placeholderColor = theme.tokens.ComponentTextInputPlaceholderColor;
            const valueColor = theme.tokens.BaseColorBlack;
            return hasValue ? valueColor : placeholderColor;
        }};
        font-size: 16px;
    }
`;
