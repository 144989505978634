import { RealState, ScopedState } from '../types/scopeState';
import { isActiveScope } from '../utils';

/**
 * Get's the ID of the primary scope
 */
export const getPrimaryScopeId = (state: RealState) => state.__primaryScope;

/**
 * Get's the map of scopes, indexed by their IDs.
 */
export const getScopes = (state: RealState) => state.__scopeMap.scopes;

/**
 * Returns the scope state for the given id
 * @param realState
 * @param scopeId
 * @param includeGlobal Merge the global state with the scope state in the returned object
 * @returns
 */
export const getScopeStateById = (realState: RealState, scopeId: string, includeGlobal = true): Record<string, any> | undefined => {
    const scopeState = realState.__scopeMap.scopes[scopeId]?.__scopeState;
    if (!scopeState) {
        return undefined;
    }
    if (includeGlobal) {
        return {
            ...realState.__global,
            ...scopeState
        };
    }
    return scopeState;
};

/**
 *
 * @param state
 * @returns
 */
export const hasMultipleActiveScopes = (state: RealState) => {
    const activeScopes = Object.values(state.__scopeMap.scopes).filter(isActiveScope);
    return activeScopes.length > 1;
};

/**
 * Gets an array of scope IDs representing the order of creation.
 */
export const getScopesOrder = (state: RealState) => state.__scopeMap.order;

/**
 * Gets an array of active scope IDs representing the order of presentation on the UI.
 */
export const getActiveScopesOrder = (realState: RealState) => {
    const currentOrder = getScopesOrder(realState);
    const activeScopeIdsInOrder: string[] = [];
    for (const scopeId of currentOrder) {
        if (!realState.__scopeMap.scopes[scopeId].__scopeMeta.deleted) {
            activeScopeIdsInOrder.push(scopeId);
        }
    }
    return activeScopeIdsInOrder;
};

/**
 * Gets the ID of the current scope
 */
export const getCurrentScopeId = (state: ScopedState) => state.__scopeId;

/**
 * Checks if the current store has scopes enabled.
 * Can be used on RealState and on Scoped State
 */
export const getIsScopedStoreEnabled = (state: any) => Boolean(state.__isScoped);

/**
 * Gets an array of initialized scope IDs representing the order of presentation on the UI.
 */
export const getInitializedScopesOrder = (realState: RealState) => {
    const currentOrder = getScopesOrder(realState);
    const activeScopeIdsInOrder: string[] = [];
    for (const scopeId of currentOrder) {
        if (realState.__scopeMap.scopes[scopeId].__scopeMeta.initialized) {
            activeScopeIdsInOrder.push(scopeId);
        }
    }
    return activeScopeIdsInOrder;
};
