import { IAdditionalSelectors, IConfigOpenDeal, IHostActions } from '../types';
import { MiddlewareManager } from './middleware';
import { Selectors } from './selectors';

export let selectors: Selectors;
export let middlewareManager: MiddlewareManager;
export let config: IConfigOpenDeal;

export interface IInitResult {
    selectors: Selectors;
    middlewareManager: MiddlewareManager;
}

export const init = (
    appName: string,
    additionalSelectors: IAdditionalSelectors,
    hostActions: IHostActions,
    initConfig: IConfigOpenDeal,
    root: string
): IInitResult => {
    selectors = new Selectors(appName, additionalSelectors);
    middlewareManager = new MiddlewareManager(selectors, hostActions, initConfig, root);
    config = initConfig;
    return {
        selectors,
        middlewareManager
    };
};
