import styled from 'styled-components';

import { Card } from '@interstate/components/Card';

export const StyledVehicleSelect = styled(Card)`
    .change-vehicle-title,
    .change-vehicle-alert-container {
        display: none;
    }

    .change-vehicle-footer-grid {
        background: ${({ theme }) => theme.tokens.BaseColorGray50};
        padding: 0;
        margin-top: 47px;
        border: 0;
        gap: 16px;

        button {
            margin: 0;
        }
    }

    .search-results-container {
        margin-bottom: -33px;
    }

    .change-vehicle-msrp-container {
        align-items: flex-end;
    }

    img {
        margin: 0;
    }

    .img-responsive {
        display: block;
        height: auto;
        max-width: 100%;
    }
`;

export const ChangeVehicleTitle = styled.h3`
    font-size: 20px;
    color: ${({ theme }) => theme.tokens.BaseColorBlue900};
    font-weight: 600;
    margin-top: 27px;
    margin-bottom: 20px;
`;

export const StyledSelectedVehicleInfo = styled.div`
    display: flex;
    justify-content: space-between;

    .selected-vehicle-info-right {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        padding-right: 8px;
    }
    .selected-vehicle-name {
        text-transform: capitalize;
    }
`;
