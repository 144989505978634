import styled from 'styled-components';

export const StyledOpenDealRoot = styled.div`
    max-width: 688px;
    padding: 0 16px;
    margin: 0 auto;
`;

export const StyledOpenDealActivity = styled.div`
    display: flex;
    gap: 32px;
    flex-direction: column;
`;

export const OpenDealHeader = styled.div`
    .open-deal-header-title {
        margin-top: 0;
        margin-bottom: 20px;
        font-size: 28px;
        font-weight: 700;
    }

    .open-deal-header-message {
        font-size: 16px;

        span {
            font-weight: bold;
        }
    }
`;

export const OpenDealCardInfo = styled.div`
    margin-top: 8px;
    color: ${({ theme }) => theme.tokens.BaseColorGray900};
    line-height: 18px;
`;
