// istanbul ignore file

// externals
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

// libraries
import { InterstateThemeProvider } from '@interstate/components/InterstateThemeProvider';
import { interstateThemeRegistry } from '@interstate/components/InterstateTheming/themes/interstateThemeRegistry';

import { setupAuthNetworkIntercepts } from '@makemydeal/dr-shared-network';

import { configureStore, historyInstance } from '@makemydeal/dr-shared-store';
import { AuthManager, ReadyContent } from '@makemydeal/dr-shared-components';
import { OPEN_DEAL_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

// styles
import { GlobalStyle } from './globalStyle/GlobalStyle.style';

// components
import { RoutingWrapper } from './shells/main/routing/routingWrapper';
import { Bootstrapper } from './shells/main/components/bootstrapper/Bootstrapper';
import { LoadingPage } from './shells/main/components/loadingPage/LoadingPage';

// utils
import { getInitialMiddleware } from './shells/main/store/initialMiddleware';
import { getRemainingMiddleware } from './shells/main/store/remainingMiddleware';

import { initialReducers } from './shells/main/reducers/initialReducers';
import { getAuthConfig } from './auth';

// we don't have an initial state - it's provided by the init response
const state = {};
const allMiddleware = getInitialMiddleware().concat(getRemainingMiddleware());
const store = configureStore(state, allMiddleware, initialReducers);

// This call will result in fetch and axios network calls being intercept to inject auth headers:
setupAuthNetworkIntercepts(OPEN_DEAL_APP_PREFIX);
const container = document.getElementById('root');

createRoot(container!).render(
    <>
        <GlobalStyle />
        <InterstateThemeProvider
            themeName="Interstate"
            applicationName="Open Deal"
            scopeName="Open Deal"
            themeRegistries={[interstateThemeRegistry]}
        >
            <ReduxProvider store={store}>
                <ConnectedRouter history={historyInstance}>
                    <AuthManager
                        appPrefix={OPEN_DEAL_APP_PREFIX}
                        offerReadyContent={(props) => <ReadyContent {...props} loadingPage={LoadingPage} />}
                        getConfig={getAuthConfig}
                    >
                        <Bootstrapper>
                            <RoutingWrapper />
                        </Bootstrapper>
                    </AuthManager>
                </ConnectedRouter>
            </ReduxProvider>
        </InterstateThemeProvider>
    </>
);
