// externals
import { useSelector } from 'react-redux';
import { StyleSheetManager as ScStyleSheetManager, ThemeProvider } from 'styled-components';

// libraries
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { Header } from '@makemydeal/dr-activities-open-deal';
import { HostEnvironment, hostUrlHelper, locHrefUtil, OPEN_DEAL_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { Alert } from '@interstate/components/Alert';
import { Action } from '@interstate/components/Action/Action/Action';
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';

// store
import { sharedBootstrapSelectors } from '@makemydeal/dr-shared-store';

// style
import { BootstrapErrorContainer, OpenDealHeader } from './BootstrapError.style';

const DEALER_SUPPROT_REQUEST_FORM = 'https://www.dealer.com/support/requestform/';

const StyleSheetManager = ScStyleSheetManager as any;

const errorMessageMap: Record<string, string> = {
    'DealXg Deal not found': 'The deal could not be opened.',
    'OrgId is not provided or not associated with MMD dealers': 'The dealer is not associated with the system.'
};

const loadingErrorMap: Record<string, string> = {
    '"dealXgId" is not available.': 'The deal could not be opened.'
};

type BootstrapErrorProps = {
    loadingError?: string;
};

export const BootstrapError: React.FC<BootstrapErrorProps> = ({ loadingError }) => {
    const theme = useInterstateTheme();
    const bootstrapMeta = useSelector(sharedBootstrapSelectors.getBootstrapMeta);
    const environment = hostUrlHelper.getHostUrlEnvironment(OPEN_DEAL_APP_PREFIX, locHrefUtil.getLocHref());
    const errorMessage = bootstrapMeta?.responseErrorMessage;
    const mappedErrorMessage: string | undefined = errorMessage && errorMessageMap[errorMessage];
    const mappedLoadingError: string | undefined = loadingError && loadingErrorMap[loadingError];

    const openSupportTicketUrl = () => {
        window.open(DEALER_SUPPROT_REQUEST_FORM);
    };

    if (loadingError && !mappedLoadingError) {
        return <>{loadingError}</>;
    }

    if (!mappedErrorMessage && !mappedLoadingError) {
        if (environment === HostEnvironment.Local) {
            return (
                <>
                    An error occurred when bootstrapping the app - you will see this when running locally if (1) the BFF hasn't yet
                    started up, (2) it isn't running properly, or (3) you have invalid query params. Try refreshing the browser if
                    the first scenario applies.
                </>
            );
        } else {
            return <>Unable to bootstrap the application - either a network error or service availability issue occurred.</>;
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <StyleSheetManager disableCSSOMInjection>
                <>
                    <Header appPrefix={OPEN_DEAL_APP_PREFIX} />

                    <BootstrapErrorContainer>
                        <OpenDealHeader>
                            <h1 className="open-deal-header-title" data-testid="open-deal-header-title">
                                Open Deal
                            </h1>
                        </OpenDealHeader>
                        <>
                            <Alert type="error" sx={{ marginBottom: theme.tokens.BaseSpace60 }} data-testid="open-deal-error-alert">
                                <Typography variant="h6">Failed to open deal</Typography>
                                <Typography variant="body-sm">Details: {mappedErrorMessage || mappedLoadingError}</Typography>
                            </Alert>

                            <Grid gap={theme.tokens.BaseSpace10} alignItems="baseline" display="flex">
                                <Typography variant="strong-md" tag="span">
                                    Please contact support:
                                </Typography>
                                <Typography variant="body-md" tag="span">
                                    1-888-895-2994 or create a
                                </Typography>
                                <Action onClick={openSupportTicketUrl} data-testid="support-ticket">
                                    <Typography variant="anchor-inline-md" tag="a" color="sem.color.surface.button.primary.rest">
                                        support ticket
                                    </Typography>
                                </Action>
                            </Grid>
                        </>
                    </BootstrapErrorContainer>
                </>
            </StyleSheetManager>
        </ThemeProvider>
    );
};
