import { IConfigOpenDeal, MiddlewareManager } from '@makemydeal/dr-activities-open-deal';
import { MiddlewareAPI } from 'redux';
import { payloadUtils } from '@makemydeal/dr-shared-ui-utils';
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import { bootstrapActionTypes } from '@makemydeal/dr-shared-store';
import { configureOpenDeal } from './config';

export let cachedOpenDealMiddlewareManager: MiddlewareManager | undefined;

// needed for testing
export const openDealMiddlewareClearCache = () => (cachedOpenDealMiddlewareManager = undefined);

// TODO: Replace OpenDealInitConfig with a type specific to open-deal (this is just a temporary fix)
type OpenDealInitConfig = {
    services: {
        gateway: any;
    };
};

export const openDealMiddleware = (store: MiddlewareAPI) => (next: any) => (action: AnyFSA) => {
    if (action.type === bootstrapActionTypes.BOOTSTRAP_SUCCESS) {
        const featureToggles = payloadUtils.getFeatureTogglesFromInitPayload(action.payload);
        // TODO: Replace OpenDealInitConfig with a type specific to open-deal (this is just a temporary fix)
        const { services: { gateway } = {} } = payloadUtils.getConfigFromInitPayload<OpenDealInitConfig>(action.payload);

        // TODO: fill with necessary config data
        const openDealConfig = {
            services: {
                gateway
            },
            featureToggles
        } as IConfigOpenDeal;

        cachedOpenDealMiddlewareManager = configureOpenDeal(openDealConfig);
    }

    if (cachedOpenDealMiddlewareManager) {
        cachedOpenDealMiddlewareManager.middleware(store)(next)(action);
    } else {
        next(action);
    }
};
