import { Card } from '@interstate/components/Card';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

import { OpenDealCardHeader } from '../openDealCardHeader/OpenDealCardHeader';
import { CustomerCardContent } from './CustomerCard.style';

type CustomerCardProps = {
    name: string;
};

export const CustomerCard = ({ name }: CustomerCardProps) => {
    const theme = useInterstateTheme();

    return (
        <Card
            sx={{ backgroundColor: theme.tokens.BaseColorGray50 }}
            data-testid="customer-card"
            header={<OpenDealCardHeader title="Customer" />}
            content={<CustomerCardContent data-testid="customer-card-name">{name}</CustomerCardContent>}
        />
    );
};
