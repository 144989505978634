// externals
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { Button } from '@interstate/components/Button';

// components
import { OpenDealDialog } from './OpenDealDialog';

// selectors
import { selectors, openDeal } from '../../../store';

export const OpenDeal: FC = () => {
    const dispatch = useDispatch();
    const dealership = useSelector(selectors.getDealership);
    const vehicle = useSelector(selectors.getVehicle);

    const isOpenDealEnabled = !!dealership && !!vehicle;

    const handleOpenDeal = () => {
        dispatch(openDeal());
    };

    return (
        <>
            <Button data-testid="footer-open-deal-button" disabled={!isOpenDealEnabled} onClick={handleOpenDeal}>
                Open
            </Button>

            <OpenDealDialog />
        </>
    );
};
