// libraries
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { IDxgVehicle } from '@makemydeal/dr-shared-types';

import { StyledSelectedVehicleInfo } from './VehicleSelect.style';

export type SelectedVehicleInfoProps = {
    selectedVehicle: IDxgVehicle;
};

export const SelectedVehicleInfo = ({ selectedVehicle }: SelectedVehicleInfoProps) => {
    const { inventoryType, year, make, model, trim, vin, stockNumber, priceCostDetails } = selectedVehicle;

    return (
        <StyledSelectedVehicleInfo data-testid="selected-vehicle-info">
            <div className="selected-vehicle-info-left">
                <div data-testid="selected-vehicle-name" className="selected-vehicle-name">
                    {inventoryType} {year} {make} {model} {trim}
                </div>
                <div data-testid="selected-vehicle-vin">{vin}</div>
                <div data-testid="selected-vehicle-stock">Stock # {stockNumber}</div>
            </div>
            {priceCostDetails && (
                <div className="selected-vehicle-info-right">
                    MSRP
                    <div data-testid="selected-vehicle-msrp">{formatDollarsAndCents(priceCostDetails.originalMsrp)}</div>
                </div>
            )}
        </StyledSelectedVehicleInfo>
    );
};
