// externals
import { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// libraries
import { Modal } from '@interstate/components/Modal';
import { Typography } from '@interstate/components/Typography';
import { Interstitial as LoadingIndicator } from '@interstate/components/Interstitial';
import { Button } from '@interstate/components/Button';

// store
import { closeOpenDealModal, selectors } from '../../../store';

// utils
import { getTargetApp } from '../../../utils';

// components
import { OpenDealDialogErrorContent } from './OpenDealDialogErrorContent';
import { LoadingIndicatorWrapper } from './LoadingIndicatorWrapper.style';
import { StyledFooterCancelContainer } from '../Footer.style';

export const OpenDealDialog: FC = () => {
    const dispatch = useDispatch();
    const isOpeningDeal = useSelector(selectors.isOpeningDeal);
    const isUpdateDealFailure = useSelector(selectors.isUpdateDealFailure);
    const target = getTargetApp();
    const targetApp = target === 'MV' ? 'Manager View' : 'Sales View';

    const handleCloseModal = () => dispatch(closeOpenDealModal());

    const footerComponent = isUpdateDealFailure ? (
        <StyledFooterCancelContainer>
            <Button data-testid="open-deal-saving-dialog-close-btn" buttonStyle="secondary" onClick={handleCloseModal}>
                Close
            </Button>
        </StyledFooterCancelContainer>
    ) : (
        <LoadingIndicatorWrapper>
            <LoadingIndicator size="small" data-testid="open-deal-saving-dialog-loading-indicator" />
        </LoadingIndicatorWrapper>
    );

    const content = isUpdateDealFailure ? (
        <OpenDealDialogErrorContent targetApp={targetApp} />
    ) : (
        <Typography variant="body-md">
            Please wait. The deal will be opened in <b data-testid="open-deal-saving-dialog-target-app">{targetApp}</b> after it is
            saved.
        </Typography>
    );

    return (
        <Modal
            show={isOpeningDeal || isUpdateDealFailure}
            size="small"
            data-testid="open-deal-saving-dialog"
            header={
                <Typography variant="h3" color="base.color.blue.900">
                    {`Open ${targetApp}`}
                </Typography>
            }
            sx={{
                '.ids-modal-fixed-footer-component': {
                    padding: '20px 24px 24px'
                }
            }}
            footer={{ footerComponent }}
        >
            {content}
        </Modal>
    );
};
