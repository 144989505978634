import { RSAA } from 'redux-api-middleware';

import { OPEN_DEAL_APP_PREFIX, apiConfigHelper, locHrefUtil, offerSessionUtils } from '@makemydeal/dr-shared-ui-utils';
import { IVehicle } from '@makemydeal/dr-platform-types';

import { Dealership } from '../types/IOpenDeal';

import { Actions } from './actions';
import { Selectors } from './selectors';
import { getTargetApp } from '../utils';

export const updateDealership = (dealer: Dealership): any => {
    return {
        type: Actions.DEALERSHIP_UPDATE,
        payload: {
            ...dealer
        }
    };
};

export const switchVehicle = (vehicle: IVehicle): any => {
    return {
        type: Actions.SWITCH_VEHICLE,
        payload: {
            ...vehicle
        }
    };
};

export const toggleChangeVehicleEditMode = (): any => {
    return {
        type: Actions.TOGGLE_CHANGE_VEHICLE_EDIT_MODE
    };
};

export const openDeal = () => {
    return {
        type: Actions.OPEN_DEAL
    };
};

export const closeOpenDealModal = () => {
    return {
        type: Actions.CLOSE_OPEN_DEAL_MODAL
    };
};

export const saveDeal = (selectors: Selectors) => (dispatch: any, getState: any) => {
    const state = getState();

    const dealXgId = offerSessionUtils.getDealXgId();
    const dealerId = selectors.getDealership(state)?.dealerId;
    const commonOrgId = selectors.getCommonOrgId(state);
    const vehicle = selectors.getVehicle(state);
    const target = getTargetApp();

    const locHref = locHrefUtil.getLocHref();
    const bffSaveDealResult = apiConfigHelper.buildBffApiUrl(OPEN_DEAL_APP_PREFIX, locHref, `deal/${dealXgId}`);
    const bffSaveDealEndpoint = bffSaveDealResult.url;

    const request = {
        endpoint: bffSaveDealEndpoint,
        method: 'PUT',
        body: JSON.stringify({
            dealerId,
            commonOrgId,
            vehicle,
            target
        }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        types: [Actions.SAVE_DEAL_REQUEST, Actions.SAVE_DEAL_SUCCESS, Actions.SAVE_DEAL_FAILURE]
    };

    dispatch({ [RSAA]: request });
};

export const openDealInTargetApp = (payload: any) => {
    const { links } = payload;
    const target = getTargetApp();
    const targetLink = target === 'MV' ? links.managerViewLink : links.salesViewLink;

    return {
        type: Actions.OPEN_DEAL_IN_TARGET_APP,
        payload: {
            link: targetLink
        }
    };
};
