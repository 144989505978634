import React, { useEffect } from 'react';
import { StyledBodyContainer } from './routes.style';
import { useLocation } from 'react-router-dom';

export const BodyContainer: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children }) => {
    const location = useLocation();
    useEffect(() => {
        // NOTE: upon navigating to a new location; scroll to the top. Moved here from `Card`.
        window.scroll({
            top: 0,
            left: 0
        });
    }, [location]);

    return <StyledBodyContainer data-testid="open-deal-body-container">{children}</StyledBodyContainer>;
};
