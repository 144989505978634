import styled from 'styled-components';

export const StyledCardHeader = styled.div`
    padding: 0;
    height: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
`;

export const StyledCardTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1;

    svg {
        margin-bottom: 2px;
    }
`;

export const StyledActionContent = styled.div`
    margin-right: 8px;
    font-size: 14px;
    font-weight: 600;
`;
