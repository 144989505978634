// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import { SimpleFSA } from '@makemydeal/dr-platform-shared';
import type { RSAACallAction, RSAACallRequest } from '@makemydeal/dr-react-18-wrappers';
import {
    apiConfigHelper,
    BffApiUrlResult,
    locHrefUtil,
    offerSessionUtils,
    getDocumentReferrer,
    DEAL_EXCHANGE_ID
} from '@makemydeal/dr-shared-ui-utils';

// types
import { bootstrapActionTypes, applicationLoadErrorActionTypes } from '@makemydeal/dr-shared-store';

export type BootstrapAppAction = SimpleFSA<typeof bootstrapActionTypes.BOOTSTRAP_REQUEST>;

export type BootstrapInitialLoadAction = SimpleFSA<typeof bootstrapActionTypes.BOOTSTRAP_INITIAL_LOAD>;

export const initialLoad = (): BootstrapInitialLoadAction => ({ type: bootstrapActionTypes.BOOTSTRAP_INITIAL_LOAD });

export const fetchBootstrapData = (appPrefix: string) => {
    return (dispatch: any) => {
        dispatch(fetchBootstrapDataWorker(appPrefix));
    };
};

export const fetchBootstrapDataWorker = (appPrefix: string) => {
    // get the endppoint from the domain called
    let endpoint: string;
    let bffApiUrlResult: BffApiUrlResult;

    const locHref = locHrefUtil.getLocHref();

    const commonOrgId = offerSessionUtils.getCommonOrgId();
    const dealXgId = offerSessionUtils.getDealXgId();

    if (dealXgId) {
        bffApiUrlResult = apiConfigHelper.buildBffApiUrl(appPrefix, locHref, 'init');

        const queryString = commonOrgId ? `?orgId=${commonOrgId}` : '';
        const base = `${bffApiUrlResult.url}/dealXg/${dealXgId}${queryString}`;

        endpoint = base;
    } else {
        // if we fall here it means we do not have the right values
        return {
            type: applicationLoadErrorActionTypes.APPLICATION_LOAD_ERROR,
            payload: {
                message: `"${DEAL_EXCHANGE_ID}" is not available.`
            }
        };
    }

    if (bffApiUrlResult.environmentName !== bffApiUrlResult.defaultEnvironmentName) {
        /* eslint-disable-next-line no-console */
        console.log(
            'OVERRIDE APPLIED: ' +
                `Environment ${bffApiUrlResult.defaultEnvironmentName} --> ${bffApiUrlResult.overriddenEnvironmentName}`
        );
    }

    const environmentType = offerSessionUtils.identifyEnvironment(bffApiUrlResult.environmentName);
    offerSessionUtils.setEnvironmentType(environmentType);

    const referrer = getDocumentReferrer();

    const request: RSAACallRequest = {
        endpoint,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'x-dr-referrer': referrer },
        types: [
            bootstrapActionTypes.BOOTSTRAP_REQUEST,
            bootstrapActionTypes.BOOTSTRAP_SUCCESS,
            {
                type: 'BOOTSTRAP_FAILURE',
                meta:
                    // this is RSAA standard code; we'll assert this coverage behavior in the reducer.
                    // istanbul ignore next
                    (_action: any, _state: any, res: any) => {
                        if (res) {
                            return {
                                status: res.status,
                                statusText: res.statusText
                            };
                        } else {
                            return {
                                statusText: 'Network request failed'
                            };
                        }
                    }
            }
        ]
    };

    return {
        [RSAA]: request
    } as RSAACallAction;
};
