import { OpenDealStateTree } from '../types/stateTreeTypes';

export const getConfig = (state: OpenDealStateTree) => {
    return state.config;
};

export const getConfigServices = (state: OpenDealStateTree) => {
    return getConfig(state).services;
};

export const getServicesBff = (state: OpenDealStateTree) => {
    return getConfigServices(state).bff;
};
