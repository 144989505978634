import { IConfigOpenDeal, Selectors, getAllRoutes, init } from '@makemydeal/dr-activities-open-deal';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { APP_NAME, OPEN_DEAL_ROOT_ROUTE } from './constants';

export const openDealAllRoutes = getAllRoutes(OPEN_DEAL_ROOT_ROUTE);
export const openDealRoutes = Object.values(openDealAllRoutes);

let openDealSelectors: Selectors;

const additionalSelectors = {};
const hostActions = {};

export const configureOpenDeal = (config: IConfigOpenDeal) => {
    const { selectors, middlewareManager } = init(APP_NAME, additionalSelectors, hostActions, config, OPEN_DEAL_ROOT_ROUTE);
    openDealSelectors = selectors;
    return middlewareManager;
};

export const getOpenDealSelectors = (): Selectors => openDealSelectors;

export const getOpenDealConfigFromState = (state: any): IConfigOpenDeal => {
    const featureToggles = featureToggleSelectors.getSimpleFeatureToggles(state);
    // TODO: fill with necessary config data
    const openDealConfig = {
        services: {},
        featureToggles
    } as IConfigOpenDeal;

    return openDealConfig;
};
