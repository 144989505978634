import { compose, Middleware } from 'redux';

import { getTargetScopeFromAction, getTargetAllScopesFromAction } from '../actions/scopedActions';
import { getScopesOrder } from '../selectors/scopedStateSelectors';
import { scopedStoreManager } from '../store/scopedStoreManager';

export const scopeMiddleware = (middleware: Middleware) => {
    return (store: any) => (next: any) => (action: any) => {
        const targetAllScopes = getTargetAllScopesFromAction(action);
        if (targetAllScopes) {
            const state = store.getState();
            const allScopeIds = getScopesOrder(state);

            const scopedMiddlewares = allScopeIds
                .map((scopeId) => scopedStoreManager.getStore(store, scopeId))
                .map((scopedStore) => middleware(scopedStore));

            return compose<Middleware>(...scopedMiddlewares)(next)(action);
        }

        const targetScope = getTargetScopeFromAction(action);
        if (targetScope === undefined) {
            // eslint-disable-next-line no-console
            console.error('Non scoped Action Detected', action);
        }

        // TODO: Decide how middlewares should run when there's no target Scope on the action.
        // For the time being this will happens on the @@init action or on actions dispatched on a RawStateProvider
        // for now we are sending undefined which will cause the middleware to operate on the Primary Scope.
        const scopedStore = scopedStoreManager.getStore(store, targetScope || undefined);

        return middleware(scopedStore)(next)(action);
    };
};
