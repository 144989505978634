import styled from 'styled-components';
import { MAX_APP_CONTAINER_WIDTH } from '../../constants';

export const HeaderRoot = styled.div`
    z-index: 1001;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.tokens.BaseColorBlue700};
`;

export const HeaderContainer = styled.div`
    background-color: ${({ theme }) => theme.tokens.BaseColorWhite};
`;

export const BridgeBarContainer = styled.div`
    width: 100%;
    padding-top: 3px;

    max-width: ${MAX_APP_CONTAINER_WIDTH};
    margin: 0 auto;

    border-bottom: 0.3rem solid ${({ theme }) => theme.tokens.BaseColorBlue700};
`;

export const TopBarContainer = styled.div`
    background-color: ${({ theme }) => theme.tokens.BaseColorWhite};
    display: flex;
    padding: 0px;
`;
