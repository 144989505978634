import { useEffect } from 'react';

export const useBeforeUnload = (preventUnload: boolean) => {
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            // This is for legacy browsers. Deprecated in modern browsers.
            event.returnValue = 'Are you sure you want to leave this page? Because any information entered will be discarded.';
        };
        if (preventUnload) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        }

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [preventUnload]);
};
