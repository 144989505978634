// externals
import { FC } from 'react';

// components
import { StyledFooterContainer, StyledFooterRow } from './Footer.style';
import { OpenDeal } from './openDeal/OpenDeal';
import { CancelDeal } from './cancelDeal/CancelDeal';

export const Footer: FC = () => {
    return (
        <StyledFooterContainer>
            <StyledFooterRow>
                <CancelDeal />
                <OpenDeal />
            </StyledFooterRow>
        </StyledFooterContainer>
    );
};
