// local components
import { ChangeVehicleFooterInterstate } from '../changeVehicleFooter/ChangeVehicleFooter.interstate';
import { ChangeVehicleHeaderInterstate } from '../changeVehicleHeader/ChangeVehicleHeader.interstate';

// store
import SearchAndSelectVehicle from './SearchAndSelectVehicle';

export type ChangeVehicleInterstateProps = {
    applyMessage?: string;
    enableConfirmModal?: boolean;
};

const ChangeVehicleInterstate = ({ applyMessage, enableConfirmModal }: ChangeVehicleInterstateProps) => {
    return (
        <div className="change-vehicle-container">
            <ChangeVehicleHeaderInterstate enableConfirmModal={enableConfirmModal} />
            <SearchAndSelectVehicle />
            <ChangeVehicleFooterInterstate applyMessage={applyMessage} enableConfirmModal={enableConfirmModal} />
        </div>
    );
};

export default ChangeVehicleInterstate;
