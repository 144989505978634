import { useSelector } from 'react-redux';
import { Root } from '@makemydeal/dr-activities-open-deal';
import { OPEN_DEAL_ROOT_ROUTE } from '../constants';
import { getOpenDealConfigFromState, getOpenDealSelectors } from '../config';

export const OpenDealContainer = () => {
    // TODO: fill with correct data
    const state = useSelector((state: any) => state);
    const config = getOpenDealConfigFromState(state);
    const selectors = getOpenDealSelectors();
    const actionCreators = {} as any;

    return <Root baseRoute={OPEN_DEAL_ROOT_ROUTE} config={config} selectors={selectors} actionCreators={actionCreators} />;
};
