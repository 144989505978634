import { ChangeVehicleInterstate } from '@makemydeal/dr-activities-change-vehicle';
import { IDxgVehicle } from '@makemydeal/dr-shared-types';

import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

import { OpenDealCardHeader } from '../openDealCardHeader/OpenDealCardHeader';
import { SelectedVehicleInfo } from './SelectedVehicleInfo';
import { ChangeVehicleTitle, StyledVehicleSelect } from './VehicleSelect.style';
import { OpenDealCardInfo } from '../Activity.style';

export type VehicleSelectProps = {
    selectedVehicle: IDxgVehicle | undefined;
    editMode: boolean;
    onEditModeChange: () => void;
};

export const VehicleSelect = ({ selectedVehicle, editMode, onEditModeChange }: VehicleSelectProps) => {
    const theme = useInterstateTheme();
    const hasVehicleSelected = !!selectedVehicle?.vin;
    const actionText = hasVehicleSelected ? 'Change Vehicle' : 'Add Vehicle';

    return (
        <StyledVehicleSelect
            sx={{ backgroundColor: theme.tokens.BaseColorGray50 }}
            data-testid="vehicle-select"
            header={
                <OpenDealCardHeader
                    title="Vehicle (required)"
                    showIcon
                    isComplete={hasVehicleSelected}
                    action={editMode ? 'Close' : hasVehicleSelected ? 'Edit' : 'Start'}
                    onActionClick={onEditModeChange}
                />
            }
            content={
                <>
                    {hasVehicleSelected && (
                        <OpenDealCardInfo>
                            <SelectedVehicleInfo selectedVehicle={selectedVehicle} />
                        </OpenDealCardInfo>
                    )}
                    {editMode && (
                        <>
                            <ChangeVehicleTitle data-testid="change-vehicle-action-title">{actionText}</ChangeVehicleTitle>
                            <ChangeVehicleInterstate applyMessage={actionText} enableConfirmModal={false} />
                        </>
                    )}
                </>
            }
        />
    );
};
