export const Actions = {
    CLOSE_OPEN_DEAL_MODAL: 'CLOSE_OPEN_DEAL_MODAL',
    DEALERSHIP_UPDATE: 'DEALERSHIP_UPDATE',
    SWITCH_VEHICLE: 'SWITCH_VEHICLE',
    TOGGLE_CHANGE_VEHICLE_EDIT_MODE: 'TOGGLE_CHANGE_VEHICLE_EDIT_MODE',
    OPEN_DEAL: 'OPEN_DEAL',
    SAVE_DEAL_REQUEST: 'SAVE_DEAL_REQUEST',
    SAVE_DEAL_SUCCESS: 'SAVE_DEAL_SUCCESS',
    SAVE_DEAL_FAILURE: 'SAVE_DEAL_FAILURE',
    OPEN_DEAL_IN_TARGET_APP: 'OPEN_DEAL_IN_TARGET_APP'
};
