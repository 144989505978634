// externals
import { PropsWithChildren } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// components
import { Routing } from './routing';

export const RoutingWrapper: React.FC<PropsWithChildren> = (props) => {
    const history = useHistory();
    const location = useLocation();
    return <Routing history={history} location={location} match={undefined as any} />;
};
