// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import { buildFromConfig, IConfigObj } from '@makemydeal/dr-common-utils';
import { RSAACallAction, RSAACallRequest } from '@makemydeal/dr-react-18-wrappers';

// consts/enums
import {
    CHANGE_VEHICLE_CANCEL,
    CHANGE_VEHICLE_CLICKED,
    FETCH_CVI_FAILURE,
    FETCH_CVI_REQUEST,
    FETCH_CVI_SUCCESS,
    NEW_VEHICLE_SELECTED,
    PROCESS_CVI_VEHICLES,
    PROCESS_SWITCH_VEHICLE,
    SEARCH_VEHICLE_FAILURE,
    SEARCH_VEHICLE_REQUEST,
    SEARCH_VEHICLE_SUCCESS,
    SEARCH_VEHICLES_CLICKED,
    VEHICLE_CHANGE_FAILURE,
    VEHICLE_CHANGE_SUCCESS,
    FETCH_CATALOG_YEARS_FAILURE,
    FETCH_CATALOG_YEARS_REQUEST,
    FETCH_CATALOG_YEARS_SUCCESS,
    FETCH_CATALOG_MAKES_FAILURE,
    FETCH_CATALOG_MAKES_REQUEST,
    FETCH_CATALOG_MAKES_SUCCESS,
    FETCH_CATALOG_MODELS_REQUEST,
    FETCH_CATALOG_MODELS_SUCCESS,
    FETCH_CATALOG_MODELS_FAILURE,
    FETCH_CATALOG_TRIMS_REQUEST,
    FETCH_CATALOG_TRIMS_SUCCESS,
    FETCH_CATALOG_TRIMS_FAILURE,
    FETCH_CATALOG_VEHICLES_REQUEST,
    FETCH_CATALOG_VEHICLES_SUCCESS,
    FETCH_CATALOG_VEHICLES_FAILURE,
    CLEAR_CATALOG_VEHICLES,
    UPDATE_CUSTOM_VEHICLE,
    CHANGE_VEHICLE_TAB_CHANGED,
    UPDATE_VEHICLE_CUSTOM_DATA,
    SET_SEARCH_QUERY,
    SEARCH_STOCK_NUMBER_REQUEST,
    SEARCH_STOCK_NUMBER_SUCCESS,
    SEARCH_STOCK_NUMBER_FAILURE,
    VEHICLE_CHANGE_FINISH
} from './actions';

// types
import type { IVehicle } from '@makemydeal/dr-platform-types';
import { ChangeVehicleCustomVehicle, VehicleCustomData } from '../types';
import { Dispatch } from 'redux';

export const fetchCviVehiclesResults = (
    query: string,
    orgId: string,
    bffEndpointConfig: IConfigObj
): ((dispatch: Dispatch<any, any>) => void) => {
    const proxyUrl = buildFromConfig(bffEndpointConfig, 'commonOrg');
    const endpoint = `${proxyUrl}/${orgId}/vehicles${query ? `?vehicleIdentifier=${query}` : ''}`;

    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                types: [FETCH_CVI_REQUEST, FETCH_CVI_SUCCESS, FETCH_CVI_FAILURE]
            } satisfies RSAACallRequest
        } as RSAACallAction);
        dispatch({ type: SET_SEARCH_QUERY, payload: { searchQuery: query } });
    };
};

export const searchVehicles = (
    query: string,
    orgId: string,
    bffEndpointConfig: IConfigObj,
    isStockNumberSearch = false
): ((dispatch: Dispatch<any, any>) => void) => {
    const proxyUrl = buildFromConfig(bffEndpointConfig);
    const queryEscaped = encodeURIComponent(query);
    const orgIdEscaped = encodeURIComponent(orgId);
    const endpoint = `${proxyUrl}/vehicle/search?${
        queryEscaped ? `vehicleIdentifier=${queryEscaped}&` : ''
    }commonOrgDealerId=${orgIdEscaped}`;

    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                types: isStockNumberSearch
                    ? [SEARCH_STOCK_NUMBER_REQUEST, SEARCH_STOCK_NUMBER_SUCCESS, SEARCH_STOCK_NUMBER_FAILURE]
                    : [SEARCH_VEHICLE_REQUEST, SEARCH_VEHICLE_SUCCESS, SEARCH_VEHICLE_FAILURE]
            } satisfies RSAACallRequest
        } as RSAACallAction);
        dispatch({ type: SET_SEARCH_QUERY, payload: { searchQuery: query } });
    };
};

export const fetchCatalogYears = (bffEndpointConfig: IConfigObj): RSAACallAction => {
    const proxyUrl = buildFromConfig(bffEndpointConfig, 'vehicle');
    const endpoint = `${proxyUrl}/catalog/years`;

    const request: RSAACallRequest = {
        endpoint,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        types: [FETCH_CATALOG_YEARS_REQUEST, FETCH_CATALOG_YEARS_SUCCESS, FETCH_CATALOG_YEARS_FAILURE]
    };
    return {
        [RSAA]: request
    } as RSAACallAction;
};

export const fetchCatalogMakes = (bffEndpointConfig: IConfigObj, year: string): RSAACallAction => {
    const proxyUrl = buildFromConfig(bffEndpointConfig, 'vehicle');
    const endpoint = `${proxyUrl}/catalog/makes/${encodeURIComponent(year)}`;

    const request: RSAACallRequest = {
        endpoint,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        types: [FETCH_CATALOG_MAKES_REQUEST, FETCH_CATALOG_MAKES_SUCCESS, FETCH_CATALOG_MAKES_FAILURE]
    };
    return {
        [RSAA]: request
    } as RSAACallAction;
};

export const fetchCatalogModels = (bffEndpointConfig: IConfigObj, year: string, make: string): RSAACallAction => {
    const proxyUrl = buildFromConfig(bffEndpointConfig, 'vehicle');
    const endpoint = `${proxyUrl}/catalog/models/${encodeURIComponent(year)}/${encodeURIComponent(make)}`;

    const request: RSAACallRequest = {
        endpoint,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        types: [FETCH_CATALOG_MODELS_REQUEST, FETCH_CATALOG_MODELS_SUCCESS, FETCH_CATALOG_MODELS_FAILURE]
    };
    return {
        [RSAA]: request
    } as RSAACallAction;
};

export const fetchCatalogTrims = (bffEndpointConfig: IConfigObj, year: string, make: string, model: string): RSAACallAction => {
    const proxyUrl = buildFromConfig(bffEndpointConfig, 'vehicle');
    const endpoint = `${proxyUrl}/catalog/trims/${encodeURIComponent(year)}/${encodeURIComponent(make)}/${encodeURIComponent(
        model
    )}`;

    const request: RSAACallRequest = {
        endpoint,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        types: [FETCH_CATALOG_TRIMS_REQUEST, FETCH_CATALOG_TRIMS_SUCCESS, FETCH_CATALOG_TRIMS_FAILURE]
    };
    return {
        [RSAA]: request
    } as RSAACallAction;
};

export const fetchCatalogVehicles = (
    bffEndpointConfig: IConfigObj,
    year: string,
    make: string,
    model: string,
    trim: string
): RSAACallAction => {
    const proxyUrl = buildFromConfig(bffEndpointConfig, 'vehicle');
    const yearEscaped = encodeURIComponent(year);
    const makeEscaped = encodeURIComponent(make);
    const modelEscaped = encodeURIComponent(model);
    const trimEscaped = encodeURIComponent(trim);

    const endpoint = `${proxyUrl}/catalog/vehicles/${yearEscaped}/${makeEscaped}/${modelEscaped}/${trimEscaped}`;

    const request: RSAACallRequest = {
        endpoint,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        types: [FETCH_CATALOG_VEHICLES_REQUEST, FETCH_CATALOG_VEHICLES_SUCCESS, FETCH_CATALOG_VEHICLES_FAILURE]
    };
    return {
        [RSAA]: request
    } as RSAACallAction;
};

export const clearCatalogVehicles = () => {
    return {
        type: CLEAR_CATALOG_VEHICLES
    };
};

export const newVehicleSelected = (vehicle: IVehicle) => {
    return {
        type: NEW_VEHICLE_SELECTED,
        payload: { vehicle }
    };
};

export const processCviVehicles = (vehicles: IVehicle[]) => {
    return {
        type: PROCESS_CVI_VEHICLES,
        payload: vehicles
    };
};

export const searchBtnClicked = () => {
    return {
        type: SEARCH_VEHICLES_CLICKED
    };
};

export const changeVehicleTabChanged = () => {
    return {
        type: CHANGE_VEHICLE_TAB_CHANGED
    };
};

export const changeVehicleClicked = () => {
    return {
        type: CHANGE_VEHICLE_CLICKED
    };
};

export const vehicleChangeCancel = () => {
    return {
        type: CHANGE_VEHICLE_CANCEL
    };
};

export const updateCustomVehicle = (vehicle: ChangeVehicleCustomVehicle | undefined) => {
    return {
        type: UPDATE_CUSTOM_VEHICLE,
        payload: vehicle
    };
};

export const updateVehicleCustomData = (vin: string, data: VehicleCustomData) => {
    return {
        type: UPDATE_VEHICLE_CUSTOM_DATA,
        payload: {
            vin,
            vehicleCustomData: data
        }
    };
};

export const finishVehicleChange = () => {
    return {
        type: VEHICLE_CHANGE_FINISH
    };
};

export const vehicleChangeSuccess = () => {
    return {
        type: VEHICLE_CHANGE_SUCCESS
    };
};

export const vehicleChangeFailure = () => {
    return {
        type: VEHICLE_CHANGE_FAILURE
    };
};

export const processSwitchVehicle = () => {
    return {
        type: PROCESS_SWITCH_VEHICLE
    };
};
