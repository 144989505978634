import { reducer as openDealReducer } from '@makemydeal/dr-activities-open-deal';
import { APP_NAME, OPEN_DEAL_ROOT_ROUTE } from './constants';
import { OpenDealContainer } from './components/OpenDealContainer';
import { openDealMiddleware } from './middleware';
import { openDealRoutes } from './config';

export const openDealConfiguration = {
    name: APP_NAME,
    defaultRoute: OPEN_DEAL_ROOT_ROUTE,
    routes: openDealRoutes,
    routingComponent: OpenDealContainer,
    middleware: openDealMiddleware,
    reducer: openDealReducer
};
