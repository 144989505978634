// libraries
import type { DealXgDetailsState, StateTree } from '@makemydeal/dr-dash-types';
import { ActivityName, ActivityState } from '@makemydeal/dr-dpm-client';
import { isValidDate } from '@makemydeal/dr-common-utils';

export const getDealXgDetailsState = (state: StateTree): DealXgDetailsState => {
    return state.dealXgDetails || ({} as any);
};

export const getGlobalCustomerId = (state: StateTree) => {
    return getDealXgDetailsState(state).globalCustomerId || '';
};

export const getRedirectionLink = (state: StateTree) => {
    return getDealXgDetailsState(state).redirectionLink || '';
};

export const getOrderDetailsLink = (state: StateTree) => {
    return getDealXgDetailsState(state).orderDetailsLink || '';
};

export const getIsEcommerceDeal = (state: StateTree) => {
    return getDealXgDetailsState(state).omsOrderNumber !== undefined || false;
};

export const getDpmStateByActivity = (state: StateTree, activity: ActivityName): ActivityState | undefined => {
    const activityMilestones = state.activityMilestones;
    const lowerCaseName = activity.charAt(0).toLowerCase() + activity.slice(1);

    return activityMilestones?.[lowerCaseName]?.state;
};

export const getLastPushToDmsUpdate = (state: StateTree): Date | null => {
    const { latestPushToDmsPlus } = getDealXgDetailsState(state);
    if (!latestPushToDmsPlus) return null;
    const date = new Date(latestPushToDmsPlus);
    return isValidDate(date) ? date : null;
};

export const getLatestPushToDmsPlus = (state: StateTree) => {
    return getDealXgDetailsState(state).latestPushToDmsPlus || undefined;
};
