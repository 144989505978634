import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';

export const BootstrapErrorContainer = styled.div`
    background-color: ${({ theme }) => theme.tokens.BaseColorWhite};
    padding-top: 5rem;
    padding-bottom: 6rem;
    min-height: 100vh;
    max-width: 48rem;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: ${BreakPoint.SMALL}) {
        margin-left: ${({ theme }) => theme.tokens.ComponentModalDialogBodyPadding};
        margin-right: ${({ theme }) => theme.tokens.ComponentModalDialogBodyPadding};
    }
`;

export const OpenDealHeader = styled.div`
    .open-deal-header-title {
        margin-top: 0;
        margin-bottom: ${({ theme }) => theme.tokens.BaseSpace50};
        font-size: 2rem;
        font-weight: ${({ theme }) => theme.tokens.BaseTypeWeightBold};
    }
`;
