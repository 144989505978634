// istanbul ignore file
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    .bridge-bar-menu-overlay { display: none !important; }

    .interstate-reference {
        z-index: 1300 !important; // @TODO: TA2381111 - waiting for the fix (interstate)
    }

    .header-root {
        background-color: #fff
    }
`;
