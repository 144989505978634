import { ConfigChangeVehicle, init, MiddlewareManager, reducer, Selectors } from '@makemydeal/dr-activities-change-vehicle';

import { additionalSelectors } from './additionalSelectors';
import { hostActions } from './hostActions';

import { APP_NAME } from './constants';

export const changeVehicleReducer = reducer;
export let changeVehicleSelectors: Selectors;
export let middlewareMgr: MiddlewareManager;

export const configureChangeVehicle = (config: ConfigChangeVehicle): MiddlewareManager => {
    const { selectors, middlewareMgr: manager } = init(APP_NAME, additionalSelectors, hostActions, config, '');
    changeVehicleSelectors = selectors;
    middlewareMgr = manager;

    return middlewareMgr;
};
